import React from "react"
import { Column, Content, Title } from "rbx"

import Layout from "src/layouts/inner"
import SEO from "src/components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="О нас" path="/about" />
    <Content size="medium" className="has-text-centered">
      <Title>О нас</Title>
      <Column.Group>
        <Column size={6} offset={3} className="has-text-left">
          <p>Команда проекта:</p>
          <ul className="nowrap">
            <li>Татьяна Каткова — автор идеи и менеджер проекта</li>
            <li>Максим Саблев — IT-разработка</li>
            <li>Полина Сапина — аналитик и консультант</li>
            <li>Тарас Фомченков — журналист</li>
            <li>Артем Генкин — вдохновитель и инициатор проекта.</li>
          </ul>
          <p>
            Нам помогают <span className="nowrap">в работе</span> студенты
            факультета журналистики{" "}
            <span className="nowrap">Института кино и телевидения (ГИТР)</span>:
          </p>
          <ul className="nowrap">
            <li>Анна Кузнецова</li>
            <li>Дарья Данелия</li>
            <li>Сабина Сихарулидзе.</li>
          </ul>
          <p>А также наши друзья:</p>
          <ul className="nowrap">
            <li>Вера Болдырева</li>
            <li>Елена Васильева</li>
            <li>Екатерина Зуйкова</li>
            <li>Светлана Калашникова.</li>
          </ul>
        </Column>
      </Column.Group>
    </Content>
  </Layout>
)

export default AboutPage
